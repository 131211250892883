

































import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsOnboardingFormOnboarding from '@/modules/onboarding/components/forms/onboarding.vue';
import {Icons} from '@/shared/components/icon/icons';
import {Action} from 'vuex-class';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import OnboardingModule from '@/modules/onboarding';
import RsOnboardingFormPreferedRoles
  from '@/modules/onboarding/components/forms/preferred-roles.vue';

@Component({
  metaInfo: {
    title: 'Select preferred roles'
  },
  components: {RsOnboardingFormPreferedRoles, RsOnboardingFormOnboarding, RsIcon, RsOnboardingHeader}
})
export default class OnboardingOnboarding extends Vue {
  @Action(OnboardingActions.LOGOUT, {namespace: OnboardingModule.namespace}) logout: () => void;

  Icons = Icons;

  mounted(){
    this.$track.sendEvent('OnboardingPreferredRoles')
  }
}
