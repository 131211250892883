












































import Component from 'vue-class-component';
import Vue from 'vue';
import {required} from 'vuelidate/lib/validators'
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import {API_SERVICE} from '@/shared/api/api.service';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';
import {Action} from 'vuex-class';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {Icons} from '@/shared/components/icon/icons';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';
import {IApiTalentUpdateRequest} from '@/shared/models/IApiTalentUpdateRequest';
import RsButton from '@/shared/components/buttons/button.vue';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {rolesInfo} from '@/shared/constants/ROLES';
import {OnboardingRouter} from '@/modules/onboarding/router';

interface FormPreferedRoles {
  roles: string[]
}

@Component({
  name: 'rs-onboarding-form-prefered-roles',
  components: {RsIcon, RsCheckboxCard, RsButton},
  validations: {
    form: {
      roles: {
        required
      },
    }
  },
})
export default class OnboardingFormPreferedRoles extends Vue {
  @Action(OnboardingActions.UPDATE_USER, {namespace: OnboardingModule.namespace}) updateUser: (user: IApiTalentUpdateRequest) => Promise<IApiTalent>;

  Icons = Icons;
  env = process.env;
  rolesInfo = rolesInfo;

  form: FormPreferedRoles = {
    roles: [],
  }

  codetables: CodetableResult = {}

  sending = {
    form: false
  }

  error = ''

  submit() {
    this.error = '';
    this.$v.form.firstName?.$touch();
    this.$v.form.lastName?.$touch();
    this.$v.form.location?.$touch();
    this.$v.form.timezone?.$touch();
    this.$v.form.terms?.$touch();
    this.$v.form.appliedForJob?.$touch();
    if (!this.$v.$invalid) {
      this.sending.form = true;
      const data: IApiTalentUpdateRequest = {
        properties: {
          TALENT_PREFERRED_ROLES: this.form.roles
        },
        uiContext: {
          onboardingStatus: ONBOARDING_STATE.WELCOME
        },
      }
      this.updateUser(data)
        .then(() => {
          this.$track.sendEvent('OnboardingPreferredRoles')
          this.$router.push({name: OnboardingRouter.WELCOME})
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.sending.form = false;
        })
    }
  }

  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(Codetables.DEVELOPER_ROLES);
  }
}
